import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import React Helmet
import { Menu, X, Mail, Phone, MapPin, Linkedin, Twitter, Youtube, Facebook, Instagram } from 'lucide-react';
import './LandingPage.css';
import limatLogo from '../asset/techlimat.png';

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');

  // Handle scroll and highlight active section
  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'services', 'about', 'contact'];
      const scrollPosition = window.scrollY;

      sections.forEach(section => {
        const element = document.getElementById(section);
        if (element) {
          const offsetTop = element.offsetTop - 100;
          const offsetBottom = offsetTop + element.offsetHeight;

          if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
            setActiveSection(section);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const features = [
    {
      title: 'Fundamentals of JAVA',
      description: 'Guide you on basic java',
      icon: '☕'
    },
    {
      title: 'DSA in JAVA',
      description: 'Guide you basic java and more focus on problems solving',
      icon: '📊'
    },
    {
      title: 'System Design',
      description: 'Teach you on wholistic design patterns',
      icon: '🎯'
    },
    {
      title: 'Fundamentals of Python',
      description: 'Guide you on basic Python',
      icon: '🐍'
    },
    {
      title: 'Fundamentals of Javascript',
      description: 'Guide you on basic Javascript',
      icon: 'JS'
    },
    {
      title: 'React JS',
      description: 'Guide you for Complete React JS with Redux',
      icon: '⚛️'
    },
    {
      title: 'Node JS',
      description: 'Guide you for basic node js, but more focus on Express JS',
      icon: '🟢'
    },
    {
      title: 'MySQL / MongoDB',
      description: 'Guide you on Basics of various database and which one should choose',
      icon: '💾'
    },
    {
      title: 'MERN Full Stack Development',
      description: 'React JS + Node JS + Express JS + MongoDB',
      icon: '⚛️'
    },
    {
      title: 'MyRS Full Stack Development',
      description: 'React JS + Spring Boot + MySQL',
      icon: '⚛️'
    },
    {
      title: 'Aptitude for Placement',
      description: 'Make you to solve problems from various topics of Quants, Reasoning, Data sufficiency etc.',
      icon: '⚛️'
    },
  ];

  const services = [
    {
      title: 'One-on-One Mentorship',
      description: 'Personalized guidance from experienced developers',
      benefits: ['Customized learning path', 'Real-time problem solving', 'Career guidance']
    },
    {
      title: 'Project-Based Learning',
      description: 'Learn by building real-world applications',
      benefits: ['Portfolio development', 'Hands-on experience', 'Industry best practices']
    },
    {
      title: 'Interview Preparation',
      description: 'Comprehensive preparation for tech interviews',
      benefits: ['Mock interviews', 'Code reviews', 'Technical discussion practice']
    },
    {
      title: 'Code Review Services',
      description: 'Get your code reviewed by experts',
      benefits: ['Best practices feedback', 'Performance optimization', 'Security reviews']
    }
  ];

  const team = [];

  return (
    <div className="landing-container">
      <Helmet>
        <meta name="description" content="TechLimat offers comprehensive tech training in Java, Python, React JS, Node JS, Full Stack Development, and more. Learn by building real-world applications." />
        <meta name="keywords" content="TechLimat, Java, DSA, Python, React JS, Node JS, Full Stack Development, Software Development, Online Learning" />
        <meta name="author" content="TechLimat" />
        <title>TechLimat - Empower Through Problem Solving</title>
      </Helmet>

      <nav className="navbar">
        <div className="nav-logo">
          <img src={limatLogo} alt="TechLimat" className="nav-logo-img" />
          <span>TechLimat</span>
        </div>
        
        <div className="nav-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </div>

        <div className={`nav-links ${isMenuOpen ? 'show' : ''}`}>
          <a 
            href="#home" 
            className={activeSection === 'home' ? 'active' : ''}
            onClick={() => setIsMenuOpen(false)}
          >
            Home
          </a>
          <a 
            href="#services" 
            className={activeSection === 'services' ? 'active' : ''}
            onClick={() => setIsMenuOpen(false)}
          >
            Services
          </a>
          <a 
            href="#about" 
            className={activeSection === 'about' ? 'active' : ''}
            onClick={() => setIsMenuOpen(false)}
          >
            Vision
          </a>
       
          <a 
            href="#contact" 
            className={activeSection === 'contact' ? 'active' : ''}
            onClick={() => setIsMenuOpen(false)}
          >
            Contact
          </a>
        </div>
      </nav>

      <div className="content-wrapper">
        <section id="home" className="hero-section">
          <div className="logo-container">
            <img src={limatLogo} alt="TechLimat Logo" className="logo" />
          </div>
          <h1 className="company-title">TechLimat</h1>
          <p className="company-tagline">"Empower Through Problem Solving"</p>
          <button onClick={() => document.getElementById('services').scrollIntoView({ behavior: 'smooth' })} className="cta-button">
            Start Learning
          </button>
        </section>

        <section id="services" className="services-section">
          <h2 className="section-title">Our Services</h2>
          <div className="services-grid">
            {features.map((service, index) => (
              <div key={index} className="service-card">
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section id="about" className="about-section">
          <h2 className="section-title">Vision</h2>
          <div className="about-content">
            <div className="about-text">
              <p className="about-description" 
               style={{ 
                fontStyle: "italic", 
                fontFamily: "'Georgia', serif", 
                color: "#929292", 
                fontSize: "1.1rem", 
                lineHeight: "1.8", 
                textAlign: "center", 
                letterSpacing: "0.5px" 
              }} >
              "To revolutionize the software industry by transforming individuals from labor-intensive and desk-bound jobs into a skilled workforce driven by analytical thinking and problem-solving. We prioritize empowering individuals from rural backgrounds, regional language learners, and first-generation graduates, creating pathways to meaningful careers in technology. Our vision is to foster inclusivity, bridge socio-economic gaps, and build a sustainable future where innovation and opportunity uplift communities and drive progress in the software ecosystem"
              </p>
            </div>
          </div>

          <h4 className="section-title" style={{ 
                // fontStyle: "italic", 
                // fontFamily: "'Georgia', serif", 
                color: "#42c4e3", 
                fontSize: "1.2rem", 
                // lineHeight: "1.8", 
                textAlign: "center", 
                letterSpacing: "0.5px" 
              }}>About Us</h4>
          <div className="about-content">
            <div className="about-text">
              <p className="about-description">
                We offer comprehensive training programs to shape the next generation of software developers, bridging the gap between theoretical knowledge and real-world application. TechLimat is committed to fostering both innovation and education in the tech industry.<br/><br/>
                We specialize in delivering cutting-edge solutions to meet the digital needs of modern businesses. Our primary focus is on developing custom software, building dynamic websites, and providing digital marketing services. We’re dedicated to helping businesses thrive by creating tailored solutions that drive growth and enhance their online presence.
              </p>
            </div>
          </div>

        </section>

        {/* <section id="about" className="about-section">
          <h2 className="section-title">About Us</h2>
          <div className="about-content">
            <div className="about-text">
              <p className="about-description">
                We offer comprehensive training programs to shape the next generation of software developers, bridging the gap between theoretical knowledge and real-world application. TechLimat is committed to fostering both innovation and education in the tech industry.<br/><br/>
                We specialize in delivering cutting-edge solutions to meet the digital needs of modern businesses. Our primary focus is on developing custom software, building dynamic websites, and providing digital marketing services. We’re dedicated to helping businesses thrive by creating tailored solutions that drive growth and enhance their online presence.
              </p>
            </div>
          </div>
        </section> */}

        <section id="contact" className="contact-section">
          <h2 className="section-title">Contact Us</h2>
          <div className="contact-container">
            <div className="contact-info">
              <div className="contact-method">
                <Mail className="contact-icon" />
                <div>
                  <h4>Email Us</h4>
                  <p>info.techlimat@gmail.com</p>
                </div>
              </div>
              <div className="contact-method">
                <Phone className="contact-icon" />
                <div>
                  <h4>Call Us</h4>
                  <p>+(91) 9597624265</p>
                </div>
              </div>
              <div className="contact-method">
                <MapPin className="contact-icon" />
                <div>
                  <h4>Visit Us</h4>
                  <p>Villivakkam, Chennai</p>
                </div>
              </div>
            </div>
            <form className="contact-form">
              <div className="form-group">
                <input type="text" placeholder="Your Name" required />
              </div>
              <div className="form-group">
                <input type="email" placeholder="Your Email" required />
              </div>
              <div className="form-group">
                <textarea placeholder="Your Message" required></textarea>
              </div>
              <button type="submit" className="submit-button">Send Message</button>
            </form>
          </div>
        </section>
      </div>

      <footer className="footer">
         <div className="footer-content">
           <div className="footer-section">
             <h4>Quick Links</h4>
             <a href="#services">Services</a>
             <a href="#about">About Us</a>
             <a href="#contact">Contact</a>
           </div>
           <div className="footer-section">
             <h4>Connect With Us</h4>
             <div className="social-links">
               <a href="#facebook"><Facebook size={20} /></a>
               <a href="#instagram"><Instagram size={20} /></a>
               <a href="#youtube"><Youtube size={20} /></a>
               <a href="#linkedin"><Linkedin size={20} /></a>
               <a href="#twitter"><Twitter size={20} /></a>
             </div>
           </div>
           <div className="footer-section">
             <h4>Contact Info</h4>
             <p><Mail size={16} /> info.techlimat@gmail.com</p>
             <p><Phone size={16} /> +(91) 9597624265</p>
             <p><MapPin size={16} /> Villivakkam, Chennai</p>
           </div>
         </div>
         <div className="footer-bottom">
           <p>&copy; 2024 TechLimat. All rights reserved.</p>
         </div>
       </footer>

      {/* <footer className="footer">
        <div className="footer-social">
          <Linkedin className="footer-icon" />
          <Twitter className="footer-icon" />
          <Youtube className="footer-icon" />
          <Facebook className="footer-icon" />
          <Instagram className="footer-icon" />
        </div>
      </footer> */}
    </div>
  );
};

export default LandingPage;









































// // src/components/LandingPage.js
// import React, { useState, useEffect } from 'react';
// // import { Menu, X, Mail, Phone, MapPin, GitHub, Linkedin, Twitter } from 'lucide-react';
// import { Menu, X, Mail, Phone, MapPin, Linkedin, Twitter, Youtube, Facebook, Instagram } from 'lucide-react';
// import './LandingPage.css';
// import limatLogo from '../asset/techlimat.png';

// const LandingPage = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [activeSection, setActiveSection] = useState('home');

//   // Handle scroll and highlight active section
//   useEffect(() => {
//     const handleScroll = () => {
//       const sections = ['home', 'services', 'about', 'contact'];
//       const scrollPosition = window.scrollY;

//       sections.forEach(section => {
//         const element = document.getElementById(section);
//         if (element) {
//           const offsetTop = element.offsetTop - 100;
//           const offsetBottom = offsetTop + element.offsetHeight;

//           if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
//             setActiveSection(section);
//           }
//         }
//       });
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   const features = [
//     {
//       title: 'Fundamentals of JAVA',
//       description: 'Guide you on basic java',
//       icon: '☕'
//     },
//     {
//       title: 'DSA in JAVA',
//       description: 'Guide you basic java and more focus on problems solving',
//       icon: '📊'
//     },
//     {
//       title: 'System Design',
//       description: 'Teach you on wholistic design patterns',
//       icon: '🎯'
//     },
//     {
//       title: 'Fundamentals of Python',
//       description: 'Guide you on basic Python',
//       icon: '🐍'
//     },
//     {
//       title: 'Fundamentals of Javascript',
//       description: 'Guide you on basic Javascript',
//       icon: 'JS'
//     },
//     {
//       title: 'React JS',
//       description: 'Guide you for Complete React JS with Redux',
//       icon: '⚛️'
//     },
//     {
//       title: 'Node JS',
//       description: 'Guide you for basic node js, but more focus on Express JS',
//       icon: '🟢'
//     },
//     {
//       title: 'MySQL / MongoDB',
//       description: 'Guide you on Basics of various database and which one should choose',
//       icon: '💾'
//     },
//     {
//       title: 'Full Stack Development',
//       description: 'MERN Full Stack Development',
//       icon: '⚛️'
//     },
//     // {
//     //   title: 'Full Stack Development',
//     //   description: 'MERN Full Stack Development',
//     //   icon: '⚛️'
//     // },
//   ];

//   const services = [
//     {
//       title: 'One-on-One Mentorship',
//       description: 'Personalized guidance from experienced developers',
//       benefits: ['Customized learning path', 'Real-time problem solving', 'Career guidance']
//     },
//     {
//       title: 'Project-Based Learning',
//       description: 'Learn by building real-world applications',
//       benefits: ['Portfolio development', 'Hands-on experience', 'Industry best practices']
//     },
//     {
//       title: 'Interview Preparation',
//       description: 'Comprehensive preparation for tech interviews',
//       benefits: ['Mock interviews', 'Code reviews', 'Technical discussion practice']
//     },
//     {
//       title: 'Code Review Services',
//       description: 'Get your code reviewed by experts',
//       benefits: ['Best practices feedback', 'Performance optimization', 'Security reviews']
//     }
//   ];

  

//   const team = [
//     // {
//     //   name: 'John Doe',
//     //   role: 'Lead Instructor',
//     //   expertise: 'Full Stack Development',
//     //   experience: '10+ years'
//     // },
//     // {
//     //   name: 'Jane Smith',
//     //   role: 'Senior Mentor',
//     //   expertise: 'System Design',
//     //   experience: '8+ years'
//     // },
//     // {
//     //   name: 'Mike Johnson',
//     //   role: 'Technical Lead',
//     //   expertise: 'Cloud Architecture',
//     //   experience: '12+ years'
//     // }
//   ];

//   return (
//     <div className="landing-container">
//       <nav className="navbar">
//         <div className="nav-logo">
//           <img src={limatLogo} alt="TechLimat" className="nav-logo-img" />
//           <span>TechLimat</span>
//         </div>
        
//         <div className="nav-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
//           {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
//         </div>

//         <div className={`nav-links ${isMenuOpen ? 'show' : ''}`}>
//           <a 
//             href="#home" 
//             className={activeSection === 'home' ? 'active' : ''}
//             onClick={() => setIsMenuOpen(false)}
//           >
//             Home
//           </a>
//           <a 
//             href="#about" 
//             className={activeSection === 'about' ? 'active' : ''}
//             onClick={() => setIsMenuOpen(false)}
//           >
//             About
//           </a>
//           <a 
//             href="#services" 
//             className={activeSection === 'services' ? 'active' : ''}
//             onClick={() => setIsMenuOpen(false)}
//           >
//             Services
//           </a>
//           {/* <a 
//             href="#about" 
//             className={activeSection === 'about' ? 'active' : ''}
//             onClick={() => setIsMenuOpen(false)}
//           >
//             About
//           </a> */}
//           <a 
//             href="#contact" 
//             className={activeSection === 'contact' ? 'active' : ''}
//             onClick={() => setIsMenuOpen(false)}
//           >
//             Contact
//           </a>
//         </div>
//       </nav>

//       <div className="content-wrapper">
//         <section id="home" className="hero-section">
//           <div className="logo-container">
//             <img src={limatLogo} alt="TechLimat Logo" className="logo" />
//           </div>
//           <h1 className="company-title">TechLimat</h1>
//           <p className="company-tagline">"Empower Through Problem Solving"</p>
//           <button onClick={() => document.getElementById('services').scrollIntoView({ behavior: 'smooth' })} className="cta-button">
//   Start Learning
// </button>

//           {/* <button className="cta-button">Start Learning</button>  */}
//         </section>

//         <section id="services" className="services-section">
//           <h2 className="section-title">Our Services</h2>
//           <div className="services-grid">
//             {features.map((service, index) => (
//               <div key={index} className="service-card">
//                 <h3 className="service-title">{service.title}</h3>
//                 <p className="service-description">{service.description}</p>
//                 {/* <ul className="service-benefits">
//                   {service.benefits.map((benefit, idx) => (
//                     <li key={idx}>{benefit}</li>
//                   ))}
//                 </ul> */}
//               </div>
//             ))}
//           </div>
//         </section>

//         <section id="about" className="about-section">
//           <h2 className="section-title">About Us</h2>
//           <div className="about-content">
//             <div className="about-text">
//               <p className="about-description">
//               We specialize in delivering cutting-edge solutions to meet the digital needs of modern businesses. Our primary focus is on developing custom software, building dynamic websites, and providing digital marketing services. We’re dedicated to helping businesses thrive by creating tailored solutions that drive growth and enhance their online presence.<br/><br/>

// Alongside our core services, we also offer comprehensive training programs to shape the next generation of software developers, bridging the gap between theoretical knowledge and real-world application. TechLimat is committed to fostering both innovation and education in the tech industry.
//                 {/* TechLimat is dedicated to shaping the next generation of software developers through 
//                 comprehensive training and hands-on experience. Our mission is to bridge the gap between 
//                 theoretical knowledge and practical implementation. */}
//               </p>
//               <div className="stats-container">
//                 <div className="stat-item">
//                   <span className="stat-number">500+</span>
//                   <span className="stat-label">Students Trained</span>
//                 </div>
//                 <div className="stat-item">
//                   <span className="stat-number">50+</span>
//                   <span className="stat-label">Corporate Clients</span>
//                 </div>
//                 <div className="stat-item">
//                   <span className="stat-number">95%</span>
//                   <span className="stat-label">Success Rate</span>
//                 </div>
//               </div>
//             </div>
//             <div className="team-grid">
//               {team.map((member, index) => (
//                 <div key={index} className="team-card">
//                   <div className="team-member-avatar">
//                     {member.name.charAt(0)}
//                   </div>
//                   <h3 className="team-member-name">{member.name}</h3>
//                   <p className="team-member-role">{member.role}</p>
//                   <p className="team-member-expertise">{member.expertise}</p>
//                   <p className="team-member-experience">{member.experience}</p>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </section>

//         <section id="contact" className="contact-section">
//           <h2 className="section-title">Contact Us</h2>
//           <div className="contact-container">
//             <div className="contact-info">
//               <div className="contact-method">
//                 <Mail className="contact-icon" />
//                 <div>
//                   <h4>Email Us</h4>
//                   <p>info.techlimat@gmail.com</p>
//                 </div>
//               </div>
//               <div className="contact-method">
//                 <Phone className="contact-icon" />
//                 <div>
//                   <h4>Call Us</h4>
//                   <p>+(91) 9597624265</p>
//                 </div>
//               </div>
//               <div className="contact-method">
//                 <MapPin className="contact-icon" />
//                 <div>
//                   <h4>Visit Us</h4>
//                   <p>Villivakkam, Chennai</p>
//                 </div>
//               </div>
//             </div>
//             <form className="contact-form">
//               <div className="form-group">
//                 <input type="text" placeholder="Your Name" required />
//               </div>
//               <div className="form-group">
//                 <input type="email" placeholder="Your Email" required />
//               </div>
//               <div className="form-group">
//                 <input type="text" placeholder="Subject" required />
//               </div>
//               <div className="form-group">
//                 <textarea placeholder="Your Message" required rows="5"></textarea>
//               </div>
//               <button type="submit" className="submit-button">Send Message</button>
//             </form>
//           </div>
//         </section>
//       </div>

//       <footer className="footer">
//         <div className="footer-content">
//           <div className="footer-section">
//             <h4>Quick Links</h4>
//             <a href="#services">Services</a>
//             <a href="#about">About Us</a>
//             <a href="#contact">Contact</a>
//           </div>
//           <div className="footer-section">
//             <h4>Connect With Us</h4>
//             <div className="social-links">
//               <a href="#facebook"><Facebook size={20} /></a>
//               <a href="#instagram"><Instagram size={20} /></a>
//               <a href="#youtube"><Youtube size={20} /></a>
//               <a href="#linkedin"><Linkedin size={20} /></a>
//               <a href="#twitter"><Twitter size={20} /></a>
//             </div>
//           </div>
//           <div className="footer-section">
//             <h4>Contact Info</h4>
//             <p><Mail size={16} /> info.techlimat@gmail.com</p>
//             <p><Phone size={16} /> +(91) 9597624265</p>
//             <p><MapPin size={16} /> Villivakkam, Chennai</p>
//           </div>
//         </div>
//         <div className="footer-bottom">
//           <p>&copy; 2024 TechLimat. All rights reserved.</p>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default LandingPage;
